<template>
    <div>
        <div id="mychartWorkorder" class="mychartWorkOrder"></div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            echarts: null
        }
    },
    props: {
        reqData: {
            default: ''
        }
    },
    watch: {
        reqData: {
            deep: true,
            immediate: true,
            handler (val) {
                this.getWorkOrderNowInfo()
            }
        }
    },
    mounted () {

    },
    methods: {
        getWorkOrderNowInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderNowInfo,
                method: "post",
                showLoading: false,
                params: {
                    ...this.reqData
                }
            }).then(({data}) => {
                let dic = {
                    "overdueNum": "超时未处理",
                    "pendingNum": "待处理",
                    "processingNum": "处理中",
                    "processOverdueNum": "超时处理中"
                }
                let dataArr = []
                let dataAll = 0
                for (const key in dic) {
                    if (Object.hasOwnProperty.call(dic, key)) {
                        dataAll += data[key]
                        dataArr.push({
                            name: dic[key],
                            value: data[key] ? data[key] : 0 
                        })
                    }
                }
                this.drawChart(dataAll, dataArr)
            })
        },
        drawChart (xAxia=0, data=[]) {
            var chartDom = document.getElementById('mychartWorkorder');
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                color: ['#29CC29', '#007AFF', '#FF3B30', '#FFA900'],
                legend: {
                    top: '20%',
                    right: '70%',
                    orient: 'vertical',
                    width: '160',
                    padding: [0, 0, 0, 0],
                    itemGap: 18,
                    itemWidth: 8,
                    itemHeight: 8,
                    icon: 'circle',
                    formatter: function (params) {
                        let a;
                        let b;
                        data.forEach((item, index) => {
                            if (item.name == params) {
                                if (item.name == '使用中') {
                                    a = "${item.name}    ${item.value}"
                                } else {
                                    a = "${item.name}       ${item.value}"
                                }

                                b = eval('`' + a + '`')
                            }
                        })

                        return b
                    }
                },
                series: [
                    {
                        name: '工单处理情况',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 6,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            normal: {
                                show: true,
                                position: 'center',
                                textBorderWidth: 0,
                                formatter: [
                                    '{a|未结束工单}',
                                    `{b|${xAxia}}`

                                ].join('\n'),
                                rich: {
                                    a: {
                                        color: '#606366',
                                        fontSize: 14,
                                        lineHeight: 26
                                    },
                                    b: {
                                        color: '#303133',
                                        fontSize: 24,
                                        fontWeight: 700,
                                        lineHeight: 35

                                    }
                                }
                            }
                        },

                        center: ['50%', '50%'],
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '12',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data
                    }
                ]
            }
            if (!!chartDom) {
                this.echarts = this.$echarts.init(chartDom);
                this.echarts.setOption(option, true);
            }

            window.addEventListener("resize", () => {
                if (this.echarts) {
                    this.echarts.resize()
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .mychartWorkOrder{
        width: 100%;
        height: 160px;
        margin-top: 20px;
    }
</style>