<template>
    <div>
        <div class="a-flex-rfec">
            <le-date-range 
                @change="dateChange"
                ref="dateRange"
                :clearable='false'
                :pickerOptions="pickerOptions" 
                :minDate.sync="startDate" 
                :maxDate.sync="endDate" 
                :defaultTime="['00:00:00','23:59:59']"
                valueFormat="yyyy-MM-dd" />
        </div>
        <div id="mychartWorkorderLine" class="mychart"></div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            startDate: this.$getDay.getTodayBeforeDays(30),
            endDate: this.$getDay.getToday(),
            choiceDate: '',
            choiceMonth: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    this.choiceMonth = minDate
                    if (maxDate) {
                        this.choiceDate = ''
                        this.choiceMonth = ''
                    }
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        if(this.timeType == 0){
                            let one = 31 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else if(this.timeType == 1){
                            let minMonth = this.choiceMonth.getMonth(),
                            lastYear = new Date(this.choiceMonth).setMonth(minMonth - 11),
                            nextYear = new Date(this.choiceMonth).setMonth(minMonth + 11);
                            return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf();
                        }
                    }else{
                        return ''
                    }
                },
            },
            timeType: 0,
        }
    },
    props: {
        reqData: {
            default: ''
        }
    },
    watch: {
        reqData: {
            deep: true,
            immediate: true,
            handler (val) {
                this.getWorkOrderTrend()
            }
        }
    },
    mounted () {
        this.drawChart()
    },
    methods: {
        getWorkOrderTrend () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderTrend,
                method: "post",
                showLoading: false,
                params: {
                    startDay: this.startDate,
                    endDay: this.endDate,
                    ...this.reqData
                }
            }).then(({data}) => {
                let dateArr = []
                dateArr = this.$getDay.enumerateDaysBetweenDates(this.startDate,this.endDate).map(item=>{
                    return {
                        day: item,
                        downNum: 0, //完成工单
                        newNum: 0,//新增工单
                    }
                })

                data.map(item=>{
                    let targetIndex = dateArr.findIndex(res=>{
                        return res.day == item.day
                    })
                    if(targetIndex != -1) {
                        dateArr[targetIndex].downNum = item.downNum
                        dateArr[targetIndex].newNum = item.newNum
                    }
                })

                let xAxis = []
                let dataDownNum = []
                let dataNewNum = []
                dateArr.map(item=>{
                    xAxis.push(item.day)
                    dataDownNum.push(item.downNum || 0)
                    dataNewNum.push(item.newNum || 0)
                })
                this.drawChart(xAxis, dataNewNum, dataDownNum)
            })
        },
        dateChange () {
            this.getWorkOrderTrend()
        },
        drawChart (xAxis=[],data1=[],data2=[]) {
            var chartDom = document.getElementById('mychartWorkorderLine');
            var option;
            option = {
                tooltip: {
                    trigger: 'axis'
                },
                color: ['#FFA900','#67C23A','#6699FF'],
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    right: "2%",
                    itemGap: 40
                },
                grid: {
                    left: '0',
                    right: '0',
                    bottom: '0',
                    top: '36px',
                    containLabel: true
                },
                dataZoom: [
                    {
                        type: 'inside',
                        startValue: 0,
                        endValue: 10
                    }
                ],
                xAxis: {
                    type: 'category',
                    min: 0,
                    axisLine: {
                        lineStyle: {
                            color: '#DDDDDD'
                        }
                    },
                    axisTick: {
                        lineStyle: {
                            color: '#DDDDDD'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                    axisLabel:{
                        color: '#999999',
                        showMaxLabel: true,
                        formatter: function (value, index) {
                            let arr = value.split('-')
                            return arr[1]+'-'+arr[2];
                        }
                    },
                    data: xAxis
                },
                yAxis: {
                    type: 'value',
                    max: 'dataMax',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine:{
                        lineStyle:{
                            color: '#EEEEEE',
                            type: 'dashed'
                        }
                    },
                },
                series: [
                    {
                        name: '新增工单',
                        data: data1,
                        type: 'line',
                        lineStyle:{
                            normal:{
                                color: '#FFA900',
                                width: 2
                            }
                        },
                        smooth: true
                    },
                    {
                        name: '处理完成',
                        data: data2,
                        type: 'line',
                        lineStyle:{
                            normal:{
                                color: '#67C23A',
                                width: 2
                            }
                        },
                        smooth: true
                    },
                ]
            };
            var myChart = this.$echarts.init(chartDom);
            myChart.clear()
            myChart.setOption(option,true);
        },
    }
}
</script>

<style lang="scss" scoped>
    .mychart{
        width: 100%;
        height: 200px;
    }
</style>