<template>
    <div class="app-body content a-flex-cfsfs">
        <div class="content-layout a-flex-rfsc">
            <span class="a-fw-700 a-flex-1">运维看板</span>
            <le-company-under-select 
                v-if="company.id == 2 && userInfo.dataPermission == 99"
                placeholder="请选择商户"
                style="padding: 0 !important;"
                large
                v-model="form.companyId">
            </le-company-under-select>
            <le-input-district-choose
                style="padding: 0 !important;"
                large
                :province.sync="form.provinceCode" 
                :city.sync="form.cityCode"
                :area.sync='form.districtCode'></le-input-district-choose>
        </div>
        <div class="a-flex-rfsfs a-mt-16 a-flex-1" style="align-items: stretch;">
            <div class="a-flex-cfsfs" style="flex:2;align-items: stretch;">
                <div class="content-layout a-flex-1" >
                    <div class="a-flex-rsbc">
                        <span class="content-main-title">巡检统计</span>
                        <div class="a-flex-rfsc font-point" @click="toInspectionMoreInfo">
                            <span class="content-main-morebtn">查看更多</span>
                            <i class="el-icon-arrow-right a-c-999 a-ml-6"></i>
                        </div>
                    </div>
                    <div class="a-flex-rfsfs a-mt-20">
                        <div class="a-flex-cfsfs content-main-card a-flex-1">
                            <div class="a-flex-rfsbl">
                                <span>累计日均巡检</span>
                                <span class="content-main-value">{{ inspectionTotal.hisAvg || 0 }}</span>
                                <span>次，异常站点</span>
                                <span class="content-main-value">{{ inspectionTotal.hisError || 0 }}</span>
                                <span>个</span>
                            </div>
                            <div class="a-flex-rfsbl a-mt-10">
                                <span>近30天日均巡检</span>
                                <span class="content-main-value">{{ inspectionTotal.day30Avg || 0 }}</span>
                                <span>次，异常站点</span>
                                <span class="content-main-value">{{ inspectionTotal.day30Error || 0 }}</span>
                                <span>个</span>
                            </div>
                        </div>
                        <div class="a-flex-cfsfs content-main-card a-ml-20" style="flex: 0.8">
                            <div class="a-flex-rfsbl">
                                <span>从未巡检站点</span>
                                <span class="content-main-value">{{ inspectionTotal.neverPatrolStation || 0 }}</span>
                                <span>个</span>
                            </div>
                            <div class="a-flex-rfsbl a-mt-10">
                                <span>近30天从未巡检站点</span>
                                <span class="content-main-value">{{ inspectionTotal.neverDay30PatrolStation || 0 }}</span>
                                <span>个</span>
                            </div>
                        </div>
                    </div>
                    <inspection-charts :reqData='form'></inspection-charts>
                </div>
                <div class="content-layout a-mt-16 a-flex-1" >
                    <div class="a-flex-rsbc">
                        <span class="content-main-title">工单情况</span>
                        <div class="a-flex-rfsc font-point" @click="toMoreWorkorderInfo">
                            <span class="content-main-morebtn">查看更多</span>
                            <i class="el-icon-arrow-right a-c-999 a-ml-6"></i>
                        </div>
                    </div>
                    <div class="a-flex-rfsfs a-mt-20" style="align-items: stretch;">
                        <div class="a-flex-cfsfs content-main-card a-flex-1">
                            <div class="a-flex-rfsbl">
                                <span>工单处理平均时效为</span>
                                <span class="content-main-value">{{ workOrderInfo.avgDownDay || 0 }}</span>
                                <span>天</span>
                            </div>
                            <div class="a-flex-rfsbl a-mt-10">
                                <span>最近30天平均时效为</span>
                                <span class="content-main-value">{{ workOrderInfo.lastThirtyDayDownDay || 0 }}</span>
                                <span>天</span>
                            </div>
                        </div>
                        <div class="a-flex-cfsfs content-main-card a-ml-20" style="flex: 0.8">
                            <div class="a-flex-rfsbl">
                                <span>当前进行中的工单</span>
                                <span class="content-main-value">{{ workOrderInfo.processingTotalNum || 0 }}</span>
                                <span>个</span>
                            </div>
                        </div>
                    </div>
                    <workorder-charts-ring :reqData='form'></workorder-charts-ring>
                    <workorder-charts-line :reqData="form"></workorder-charts-line>
                </div>
            </div>
            <div class="content-layout a-ml-16 a-flex-cfsfs" style="flex: 1;align-items:stretch">
                <div class="a-flex-rsbc">
                    <span class="content-main-title">员工效率</span>
                    
                </div>
                <div class="a-flex-rfsfs a-mt-20" style="align-items: stretch;">
                    <div class="a-flex-cfsfs content-main-card a-flex-1">
                        <div class="a-flex-rfsbl">
                            <span>平均单日完成工单</span>
                            <span class="content-main-value">{{ rbacUserEfficiency.avgWorkOrderEfficiency || 0 }}</span>
                        </div>
                        <div class="a-flex-rfsbl a-mt-10">
                            <span>平均单日完成巡检</span>
                            <span class="content-main-value">{{ rbacUserEfficiency.avgPatrolEfficiency || 0 }}</span>
                        </div>
                    </div>
                </div>
                <staff-charts class="a-flex-1" :reqData='form'></staff-charts>
            </div>
        </div>
    </div>
</template>

<script>
import InspectionCharts from "./inspection/inspection-charts.vue";
import StaffCharts from './inspection/staff-charts.vue';
import WorkorderChartsLine from './inspection/workorder-charts-line.vue';
import workorderChartsRing from './inspection/workorder-charts-ring.vue';
import { mapState } from 'vuex';
export default {
    components: {
        InspectionCharts,
        workorderChartsRing,
        WorkorderChartsLine,
        StaffCharts
    },
    data () {
        return {
            form: {
                companyId: '',
                provinceCode: '',
                cityCode: '',
                districtCode: ''
            },
            inspectionTotal: {},
            workOrderInfo: {},
            rbacUserEfficiency: {}
        }
    },
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    watch: {
        form: {
            deep: true,
            handler (val) {
                this.getPatrolTotal()
                this.getWorkOrderNowInfo()
                this.getRbacUserEfficiency()
            }
        }
    },
    mounted () {
        this.getPatrolTotal()
        this.getWorkOrderNowInfo()
        this.getRbacUserEfficiency()
    },
    methods: {
        getPatrolTotal () {
            this.$Axios._post({
                url: this.$Config.apiUrl.patrolTotal,
                method: "post",
                showLoading: false,
                params: {
                    day: 30,
                    // startDate: this.$getDay.getToday(),
                    startDate: '2024-08-01',
                    ...this.form
                }
            }).then(({data}) => {
                this.inspectionTotal = Object.assign({}, data)
            })
        },
        getWorkOrderNowInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderNowInfo,
                method: "post",
                showLoading: false,
                params: {
                    ...this.form
                }
            }).then(({data}) => {
                this.workOrderInfo = Object.assign({}, data)
            })
        },
        getRbacUserEfficiency () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getTotalEfficiency,
                method: "post",
                showLoading: false,
                params: {
                    ...this.form
                }
            }).then(({data}) => {
                this.rbacUserEfficiency = Object.assign({}, data)
            })
        },
        toInspectionMoreInfo () {
            this.$router.push({
                path: '/dataPanel/data-inspection-info',
                query:{
                    provinceCode: this.form.provinceCode,
                    cityCode: this.form.cityCode,
                    districtCode: this.form.districtCode,
                    companyId: this.form.companyId
                }
            })
        },
        toMoreWorkorderInfo () {
            this.$router.push({
                path: '/dataPanel/data-inspection-workorder',
                query:{
                    provinceCode: this.form.provinceCode,
                    cityCode: this.form.cityCode,
                    districtCode: this.form.districtCode,
                    companyId: this.form.companyId
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content{
    margin-top: 16px;
    overflow-y: auto;
    padding-bottom: 32px;
    height: 100%;
    align-items: stretch;
    .content-layout{
        background: #ffffff;
        border-radius: 4px;
        padding: 20px;
        .content-main-title{
            font-size: 14px;
            color: #333333;
            font-weight: 700;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
        }
        .content-main-title::before{
            content: "";
            display: inline-block;
            width: 4px;
            height: 14px;
            border-radius: 4px;
            background: #007af0;
            margin-right: 8px;
        }
        .content-main-morebtn{
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .content-main-card {
            padding: 12px;
            border-radius: 8px;
            font-size: 12px;
            color: #333333;
            background: rgb(242, 246, 252);
            font-weight: 500;
        }
        .content-main-value{
            font-size: 18px;
            font-weight: 700;
            padding: 0 10px;
            color: #FE7B11;
        }
    }
}
    
</style>