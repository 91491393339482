<template>
  <div class="a-flex-cfsfs" >
    <div class="a-flex-rfec a-w-100">
        <le-date-range
            ref="dateRange"
            :clearable='false'
            @change="dateChange"
            :pickerOptions="pickerOptions"
            :minDate.sync="startDate"
            :maxDate.sync="endDate"
            :defaultTime="['00:00:00', '23:59:59']"
            valueFormat="yyyy-MM-dd"
        />
    </div>
    <div id="mychartStaff" class="mychart a-flex-1"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        startDate: this.$getDay.getTodayBeforeDays(30),
        endDate: this.$getDay.getToday(),
      choiceDate: "",
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate = minDate.getTime();
          if (maxDate) {
            this.choiceDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.choiceDate) {
            let one = 31 * 24 * 3600 * 1000;
            const minTime = this.choiceDate - one;
            const maxTime = this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          } else {
            return "";
          }
        },
        tableData: []
      },
    };
  },
  props: {
        reqData: {
            default: ''
        }
    },
    watch: {
        reqData: {
            deep: true,
            immediate: true,
            handler (val) {
                this.getRbacUserEfficiency()
            }
        }
    },
  mounted() {
  },
  beforeDestroy () {
  },
  methods: {
    getRbacUserEfficiency () {
        this.$Axios._post({
            url: this.$Config.apiUrl.getRbacUserEfficiency,
            method: "post",
            showLoading: false,
            params: {
                startDay: this.startDate,
                endDay: this.endDate,
                ...this.reqData
            }
        }).then(({data}) => {
            let xAxis = []
            let data1= []
            let data2 = []
            this.tableData = data.rbacUserEfficiency.sort((a,b)=>{
                return (a.downWorkOrder || 0) + (a.downPatrol || 0) < (b.downWorkOrder || 0) + (b.downPatrol || 0) ? -1 : 1
            })
            this.tableData.map(item=>{
                xAxis.push(item.rbacUserName)
                data1.push(item.downWorkOrder || 0)
                data2.push(item.downPatrol || 0)
            })
            this.drawChart(xAxis, data1, data2)
        })
    },
    dateChange () {
        this.getRbacUserEfficiency()
    },
    drawChart(xAxis = [], data1 = [], data2 = []) {
      var chartDom = document.getElementById("mychartStaff");
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
            
        },
        dataZoom: [
            {
                type: 'slider',
                startValue: xAxis.length > 15 ? xAxis.length - 15 : 0,
                endValue: xAxis.length,
                yAxisIndex: [0],
                handleSize: 20,
                width: 20,
            },
        ],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: xAxis,
        },
        series: [
          {
            name: "完成工单",
            type: "bar",
            stack: "total",
            barWidth: 20,
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: data1,
          },
          {
            name: "完成巡检",
            type: "bar",
            stack: "total",
            barWidth: 20,
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: data2,
          },
        ],
      };
      this.myChart = this.$echarts.init(chartDom);

      this.myChart.setOption(option);
      let _this = this
       this.$nextTick(()=>{
        // this.myChart.getZr().on('click', params => {
        //     // 规定代码块↓↓
        //     const pointInPixel = [params.offsetX, params.offsetY];
        //     if (this.myChart.containPixel('grid', pointInPixel)) {
        //         const xIndex = this.myChart.convertFromPixel({seriesIndex: 0}, [params.offsetX, params.offsetY])[0];
        //         // 规定代码块↑↑
        //         // x轴数据
        //         this.tableData.forEach((item, index) => {
        //             if (index === xIndex) {
        //                 _this.$router.push({
        //                     path: '/dataPanel/data-staff-info',
        //                     query:{
        //                         ..._this.reqData,
        //                         rbacUserId: _this.tableData[params.dataIndex].rbacUserId
        //                     }
        //                 })
        //             }
        //         });
        //     }
        // });
        this.myChart.on('click', function (params) {
            // params.event 包含了原生的事件对象
            // params.seriesIndex 表示系列的索引
            // params.dataIndex 表示数据的索引
            // params.name 表示数据点的名字
            // params.value 表示数据点的值
            // 可以根据这些参数进行相应的操作
            _this.$router.push({
                path: '/dataPanel/data-staff-info',
                query:{
                    ..._this.reqData,
                    rbacUserId: _this.tableData[params.dataIndex].rbacUserId
                }
            })
        });
       })
    },
  },
};
</script>

<style lang="scss" scoped>
.mychart {
  width: 100%;
}
</style>