<template>
    <div>
        <jd-other-date-range
            ref="dateRange"
            :clearable='false'
            @change="dateChange"
            :pickerOptions="pickerOptions" 
            :timeType.sync="timeType"
            :timeTypeDic="['日', '月']"
            valueFormat="yyyy-MM-dd"
            :minDate.sync="startDate" 
            :maxDate.sync="endDate">
        </jd-other-date-range>
        <div id="mychartInspection" class="mychart"></div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            startDate: this.$getDay.getTodayBeforeDays(14),
            endDate: this.$getDay.getToday(),
            choiceDate: '',
            choiceMonth: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    this.choiceMonth = minDate
                    if (maxDate) {
                        this.choiceDate = ''
                        this.choiceMonth = ''
                    }
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        if(this.timeType == 0){
                            let one = 31 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else if(this.timeType == 1){
                            let minMonth = this.choiceMonth.getMonth(),
                            lastYear = new Date(this.choiceMonth).setMonth(minMonth - 11),
                            nextYear = new Date(this.choiceMonth).setMonth(minMonth + 11);
                            return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf();
                        }
                    }else{
                        return ''
                    }
                },
            },
            timeType: 0,
        }
    },
    props: {
        reqData: {
            default: ''
        }
    },
    watch: {
        reqData: {
            deep: true,
            immediate: true,
            handler (val) {
                this.getPatrolBarChart()
            }
        },
        timeType: {
            handler () {
                if(this.timeType == 0){
                    this.endDate = this.$getDay.getToday()
                    this.startDate = this.$getDay.getTodayBeforeDays(14)
                }else if(this.timeType == 1) {
                    this.endDate = this.$getDay.getSomeMonthDays(this.$getDay.getToday(),'YYYY-MM-DD').starttime
                    this.startDate = this.$getDay.getSomeMonthDays(this.$getDay.getTodayBeforeMonth(12),'YYYY-MM-DD').starttime
                }
            }
        }
    },
    mounted () {

    },
    methods: {
        getPatrolBarChart () {
            this.$Axios._post({
                url: this.$Config.apiUrl.patrolBarChart,
                method: "post",
                showLoading: false,
                params: {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    countType: this.timeType,
                    ...this.reqData
                }
            }).then(({data}) => {
                let dateArr = []
                if(this.timeType == 0) {
                    dateArr = this.$getDay.enumerateDaysBetweenDates(this.startDate,this.endDate).map(item=>{
                        return {
                            day: item,
                            errorTotal: 0, //异常次数
                            patrolTotal: 0,//巡检次数
                        }
                    })
                }else if(this.timeType == 1) {
                    dateArr = this.$getDay.enumerateMonthsBetweenDates(this.startDate,this.endDate).map(item=>{
                        return {
                            day: item,
                            errorTotal: 0, //异常次数
                            patrolTotal: 0,//巡检次数
                        }
                    })
                }

                data.map(item=>{
                    let targetIndex = dateArr.findIndex(res=>{
                        return res.day == item.countDate
                    })
                    if(targetIndex != -1) {
                        dateArr[targetIndex].errorTotal = item.errorTotal
                        dateArr[targetIndex].patrolTotal = item.patrolTotal
                    }
                })

                let xAxis = []
                let dataErrorTotal = []
                let dataPatrolTotal = []
                dateArr.map(item=>{
                    xAxis.push(item.day)
                    dataErrorTotal.push(item.errorTotal || 0)
                    dataPatrolTotal.push(item.patrolTotal || 0)
                })
                this.drawChart(xAxis, dataErrorTotal, dataPatrolTotal)
            })
        },
        dateChange () {
            this.$nextTick(()=>{
                this.getPatrolBarChart()
            })
        },
        drawChart (xAxis=[],data1=[],data2=[]) {
            var chartDom = document.getElementById('mychartInspection');
            var option;
            option = {
                grid: {
                    left: '0',
                    right: '0',
                    bottom: '0',
                    top: '36px',
                    containLabel: true
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                },
                dataZoom: [
                    {
                        type: 'inside',
                        startValue: 0,
                        endValue: 15
                    },
                ],
                xAxis: {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: '#DDDDDD'
                        }
                    },
                    axisTick: {
                        lineStyle: {
                            color: '#DDDDDD'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                    axisLabel:{
                        color: '#999999',
                        showMaxLabel: true
                    },
                    data: xAxis
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine:{
                        lineStyle:{
                            color: '#EEEEEE',
                            type: 'dashed'
                        }
                    },
                },
                series: [
                    {
                        name: '异常次数',
                        data: data1,
                        type: 'bar',
                        barWidth: 15,
                        label: {
                            normal:{
                                show:true,
                                position: 'top',
                                textStyle:{
                                    color: '#606366',
                                }
                                
                            }
                        },
                        itemStyle: {
                            normal:{
                                color: '#6699FF',
                                barBorderRadius: [4,4,0,0],
                            }
                        },
                    },
                    {
                        name: '巡检次数',
                        data: data2,
                        type: 'bar',
                        barWidth: 15,
                        label: {
                            normal:{
                                show:true,
                                position: 'top',
                                textStyle:{
                                    color: '#606366',
                                }
                                
                            }
                        },
                        itemStyle: {
                            normal:{
                                color: '#E6A23C',
                                barBorderRadius: [4,4,0,0],
                            }
                        },
                    }
                ],
            };
            var myChart = this.$echarts.init(chartDom);

            myChart.setOption(option);
        },
    }
}
</script>

<style lang="scss" scoped>
    .mychart{
        width: 100%;
        height: 200px;
    }
</style>